//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors 122E33​
// Primary
$primary: #122E33;
$primary-active: #0c2024;
$primary-light: #e0f0f5;
$primary-inverse: #ffffff;

// Success
$success: #01E299;
$success-active: #04aa77;
$success-light: #e1faee;
$success-inverse: #ffffff;

// Info
$info: #44BFEE;
$info-active: #00a3e9;
$info-light: #e0f4fc;
$info-inverse: #ffffff;

// Danger
$danger: #EC375E;
$danger-active: #c52d57;
$danger-light: #fde5eb;
$danger-inverse: #ffffff;

// Warning
$warning: #FF805A;
$warning-active: #fd470e;
$warning-light: #fce8e5;
$warning-inverse: #ffffff;
